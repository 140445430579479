import React, { Component } from "react";
import Fade from "react-reveal";
import artImage from "../../assets/images/story.png";
import WomenEmpower from "../../assets/images/Womenempower.jpg";
import Sustainable from "../../assets/images/sustainable.webp";
import coverOne from "../../assets/images/coverOne.png"
import coverTwo from "../../assets/images/coverTwo.jpg"
import trendOne from "../../assets/images/COVER-01.jpg"
import trendTwo from "../../assets/images/COVER-02.jpg"
import trendThree from "../../assets/images/COVER-03.jpg"
import TrendFullImage from "../../assets/images/DESIGN PACKAGE1-01_trend.jpg"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import JoinUsMembership from "./JoinUsMembership";
import ContactFormJoinUs from "../ContactFormJoinUs"
import img1 from "../../assets/images/trend/COVER-01.jpg"
import img2 from "../../assets/images/trend/COVER-02.jpg"
import img3 from "../../assets/images/trend/COVER-03.jpg"
import img4 from "../../assets/images/trend/GRAPHICS & DESIGN-04.png"
import LazyLoad from "react-lazyload";

import { Helmet } from "react-helmet";
export class DezininCarosol extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: Adds a smooth scrolling effect
      });

    }, 100);
  }
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

    return (
      <section id="collection" className="collection_carosol trend_comp_main">
        <Helmet>
          <title>Trend</title>
          <meta name="description" content="Dezinin-Trend" />
          <link rel="canonical" href="https://dezinin.com/trend" />
        </Helmet>
        {/* <Fade  distance="40px"> */}
        {/* <div className="row">
          <div style={{ textAlign: "center" }}>
          
            <div
           
            >
              <Carousel swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px">
                <div className="carosel_part">
                  <img src={trendOne} />
                 
                </div>
                <div className="carosel_part">
                  <img src={trendTwo} />
                 
                </div>
                <div className="carosel_part">
                  <img src={trendThree} />
                  
                </div>
              </Carousel>
            </div>
          </div>
        </div> */}
        <div className="trend_img_main">
          <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img1} alt="no_img" />
            </LazyLoad>
          </div>
          <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img2} alt="no_img" />
            </LazyLoad>

          </div>
          <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img3} alt="no_img" />
            </LazyLoad>
          </div>
          <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img4} alt="no_img" />
            </LazyLoad>
          </div>
        </div>
        <div className="row">
          <ContactFormJoinUs />


        </div>
        {/* </Fade> */}
      </section>
    );
  }
}

export default DezininCarosol;
