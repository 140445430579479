
import "./ContactForm.css";
import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import Loader from "./Loader/Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const handleChangeName = (e) => {
        setName(e.target.value)
    }
    const handleChangeCompanyname = (e) => {
        setCompanyname(e.target.value)
    }
    const handleChangeCity = (e) => {
        setCity(e.target.value)
    }
    const handleChangeCountry = (e) => {
        setCountry(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
    }
    const clearState = () => {
        setName("")
        setCompanyname("")
        setEmail("")
        setPhone("")
        setCity("")
        setCountry("")
        setMessage("")

    }
    function isValidEmail(email) {
        // Define a regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleSubmit = (e) => {
        //  let isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (message && isValidEmail(email)) {
            setLoader(true)
            let submitObj = {
                name: name,
                companyname: companyname,
                email: email,
                city: city,
                country: country,
                phone: phone,
                message: message,
            }
            axios({
                method: 'POST',
                // url: 'http://localhost:5000/sendmail_contact',
                url: 'https://send-email-one.vercel.app/sendmail_contact',

                data: submitObj
            }).then((res) => {
                toast("Successfully send your mail")
                setLoader(false)
                clearState()

            }).catch((err) => {
                console.log("response err", err)
                setLoader(false)
                toast("Something wrong")
            });
        } else {
            setLoader(false)
        }

    }
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Optional: Adds a smooth scrolling effect
              });
            
        }, 500);
    }, []);
    return (
        <div className="contact-container">
            <Helmet>
        <title>Dezinin | Contact us</title>
        <meta name="description" content="Dezinin-Trend" />
        <link rel="canonical" href="https://dezinin.com/contact" />
         </Helmet>
            <h2>Contact Us</h2>
            <div className="contact-content">
                {/* Left Section: Address */}
                <div className="contact-details">
                    <div className="contact-address">
                        <h3>BANGLADESH OFFICE</h3>
                        <p>
                            House-03, 4th Floor, Road-16, Sector-11, Uttara, Dhaka-1230
                        </p>
                        <p>Cell: +8801860963169, contactus@dezinin.com</p>

                        <h3 className="outside-office">NEW YORK OFFICE</h3>
                        <p>2244 Jackson Avenue Suite# 2307</p>
                        <p>Long Island City, New York 11101</p>
                        <p>Cell: +1 347-986-3088</p>
                    </div>
                </div>

                {/* Right Section: Form */}
                <div className="email-request-form">
                    <h3>Email Request Form</h3>
                    <p>
                        Please use the form below to send us your query. Our team will
                        respond within 24 hours.
                    </p>

                    <div className="email_form">
                        <div className="email_form_name">
                            <label>Name: *</label>
                            <input onChange={(e) => handleChangeName(e)} value={name} type="text" id="name" name="name" required />
                        </div>
                        <div className="email_form_company">
                            <label>Company Name:</label>
                            <input onChange={(e) => handleChangeCompanyname(e)} value={companyname} type="text" id="companyname" name="companyname" />
                        </div>
                    </div>

                    <div className="email_form">

                        <div className="email_form_company">
                            <label>Email Address: *</label>
                            <input onChange={(e) => handleChangeEmail(e)} value={email} type="email" id="email" name="email" required />
                        </div>
                        <div className="email_form_company">

                            <label>Phone Number:</label>
                            <input onChange={(e) => handleChangePhone(e)} value={phone} type="text" id="phone" name="phone" />
                        </div>
                    </div>


                    <div className="email_form">

                        <div className="email_form_company">

                            <label>City: * &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </label>
                            <input onChange={(e) => handleChangeCity(e)} value={city} type="text" id="city" name="city" required />
                        </div>
                        <div className="email_form_company">

                            <label>Country: * &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; </label>
                            <input onChange={(e) => handleChangeCountry(e)} value={country} type="text" id="country" name="country" required />
                        </div>
                    </div>





                    <label>Message: *</label>
                    <textarea onChange={(e) => handleChangeMessage(e)} value={message} id="message" name="message" required></textarea>


                    <div className='submit_btn_diiv'>
                        <button className="submit_btn" onClick={(e) => handleSubmit(e)}>Submit Enquiry</button>

                        {loader ? <Loader /> : ""}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ContactForm;
