import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import imageOne from "../../assets/profilepic.jpg";
import Fade from "react-reveal";
import Ticker from "react-ticker";
// import "./styles.css";

export class News extends Component {
  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
    return (
      <section id="news_ticker" className="news_section">
        <Fade left duration={1000} distance="40px">
          <div className="row">
          <Ticker>
      {({ index }) => (
        <>
          <h1 style={{ paddingRight: "0.5em" }} className="notice_scrolling upper_case">
            {/* This is the Headline of element #{index}! */}
            Be A Part Of A <span className="notice_eco_part">ECO FRIENDLY</span>  lifestyle.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                      
          </h1>
        </>
      )}
    </Ticker>
           
          </div>
        </Fade>
      </section>
    );
  }
}

export default News