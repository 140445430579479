import React, { Component } from 'react'
// import Collections from "../homePage/Collections"
import ReactGA from "react-ga";
import $ from "jquery";
import {
    AdvancedImage,
    lazyload,
    responsive,
    placeholder,
  } from "@cloudinary/react";
//   import imageOne from "../../assets/images/1.jpg";
 import JoinUsMembership from '../homePage/JoinUsMembership';
// import DezininCarosol from '../Carousel/Carousel';
// import serviceOne from "../../assets/images/service/GRAPHICS & DESIGN-01.png"
// import serviceTwo from "../../assets/images/service/GRAPHICS & DESIGN-02.png"
// import serviceThree from "../../assets/images/service/GRAPHICS & DESIGN-03.png"
// import serviceFour from "../../assets/images/service/GRAPHICS & DESIGN-04.png"
// import TrendFullImage from "../../assets/images/DESIGN PACKAGE1-01_trend.jpg"
import TrendFullImage from "../../assets/images/DESIGN PACKAGE1-01_trend.jpg"
import { Helmet } from "react-helmet";
import ContactFormJoinUs from "../ContactFormJoinUs"
import LazyLoad from 'react-lazyload';
import img1 from "../../assets/images/sustainability/SUS-01.jpg"
import img2 from "../../assets/images/sustainability/SUS-02.jpg"
import img3 from "../../assets/images/sustainability/SUS-03.jpg"
export class GraphicsAndDesign extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isEdit: false,
          department_name: "",
          foo: "bar",
          resumeData: {}
    
        };
        ReactGA.initialize("UA-110570651-1");
        ReactGA.pageview(window.location.pathname);
    
      }
    getResumeData() {
        $.ajax({
          url: "./resumeData.json",
          dataType: "json",
          cache: false,
          success: function(data) {
            this.setState({ resumeData: data });
          }.bind(this),
          error: function(xhr, status, err) {
            console.log(err);
            alert(err);
          }
        });
      }
    
      componentDidMount() {
        setTimeout(() => {
          window.scrollTo({
              top: 0,
              behavior: 'smooth', // Optional: Adds a smooth scrolling effect
            });
          
      }, 500);
      }
    
  render() {
    const carouselData = [
      // {
      //   text: "Beautiful sunset over the mountains",
      //   img: serviceOne
      // },
      // {
      //   text: "Calm beach with crystal clear water",
      //   img: serviceTwo
      // },
      // {
      //   text: "Lush green forest trail",
      //   img: serviceThree
      // },
      // {
      //   text: "Skyline of a bustling city at night",
      //   img: serviceFour
      // }
    ];
    return (
      <div className='sustainability_main'>
        <Helmet>
        <title>Dezinin | Sustainability</title>
        <meta name="description" content="Dezinin-Sustainability" />
        <link rel="canonical" href="https://dezinin.com/sustainability" />
         </Helmet>
         {/* <DezininCarosol carouselData={carouselData}/> */}
       
        {/* <div className='new_arrivals'>
        <Collections title={"New Arrivals"} data={this.state.resumeData.new_arrival} />

        </div>
        <div className='shop_by_category'>
        <Collections title={"Shop By Category"} data={this.state.resumeData.collection} />

        </div> */}
         <div className="graphics_img_main">
         <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img1} alt="no_img" />
            </LazyLoad>
          </div> 
          <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img2} alt="no_img" />
            </LazyLoad>
          </div> 
          <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img3} alt="no_img" />
            </LazyLoad>
          </div> 
          {/* <div className="row trend_image_full">
            <LazyLoad height={200} offset={100}>
              <img src={img1} alt="no_img" />
            </LazyLoad>
          </div> */}

        </div>
        <div>
        <ContactFormJoinUs/>

        </div>
      </div>
    )
  }
}

export default GraphicsAndDesign