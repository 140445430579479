import React from "react";
import "./ContentNotAvailable.css"; // Import the CSS file
import { useHistory } from 'react-router-dom';

const ContentNotAvailable = () => {
	const history = useHistory();

  return (
    <div className="content-not-available">
      <div className="content-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-alert-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
      </div>
      <h1>Content Not Available</h1>
      <p>We're sorry, but the content you're looking for is unavailable at the moment.</p>
      <button className="retry-button" onClick={() => history.push(`/`)}>
        Home
      </button>
    </div>
  );
};

export default ContentNotAvailable;
