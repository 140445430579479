import React, { Fragment, useEffect, useState } from "react";
import "./Collection.css";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import img1 from "../assets/images/collections/1-CR Hoody with check (1).png"
import img1one from "../assets/images/collections/1/CR Hoody with check (1).png"
import img1two from "../assets/images/collections/1/CR Hoody with check (2).png"
import img1three from "../assets/images/collections/1/CR Hoody with check (3).png"

import img2one from "../assets/images/collections/2/1.webp"
import img2two from "../assets/images/collections/2/2.webp"
import img2three from "../assets/images/collections/2/3.webp"

import img3one from "../assets/images/collections/3/CR PDR shirt-jacket(REWORK1)_4.png"
import img3two from "../assets/images/collections/3/CR PDR shirt-jacket(REWORK1)_5.png"
import img3three from "../assets/images/collections/3/CR PDR shirt-jacket(REWORK1)_6.png"

import img4one from "../assets/images/collections/4/CR Jogger with check4 (1).png"
import img4two from "../assets/images/collections/4/CR Jogger with check4 (2).png"
import img4three from "../assets/images/collections/4/CR Jogger with check4 (3).png"

import img5one from "../assets/images/collections/5/Lapel collar rec.png"
import img5two from "../assets/images/collections/5/Lapel collar rec_1.png"
import img5three from "../assets/images/collections/5/Lapel collar rec_2.png"

import img6one from "../assets/images/collections/6/4pkt jacket_3.png"
import img6two from "../assets/images/collections/6/4pkt jacket_3_1.png"
import img6three from "../assets/images/collections/6/4pkt jacket_3_2.png"

import img7one from "../assets/images/collections/7/4pkt jacket_Colorway A.png"
import img7two from "../assets/images/collections/7/4pkt jacket_Colorway A_1.png"
import img7three from "../assets/images/collections/7/4pkt jacket_Colorway A_2.png"

import img8one from "../assets/images/collections/8/1.webp"
import img8two from "../assets/images/collections/8/2.webp"
import img8three from "../assets/images/collections/8/3.webp"

import img9one from "../assets/images/collections/9/1.webp"
import img9two from "../assets/images/collections/9/2.webp"
import img9three from "../assets/images/collections/9/3.webp"

import img10one from "../assets/images/collections/10/QUILTED GILET(opt1) (1).png"
import img10two from "../assets/images/collections/10/QUILTED GILET(opt1) (1)_1.png"
import img10three from "../assets/images/collections/10/QUILTED GILET(opt1) (1)_2.png"

import img11one from "../assets/images/collections/11/Style 02_Colorway A.png"
import img11two from "../assets/images/collections/11/Style 02_Colorway A_1.png"
import img11three from "../assets/images/collections/11/Style 02_Colorway A_2.png"

import img12one from "../assets/images/collections/12/1.webp"
import img12two from "../assets/images/collections/12/2.webp"
import img12three from "../assets/images/collections/12/3.webp"

import img13one from "../assets/images/collections/13/2_4.png"
import img13two from "../assets/images/collections/13/2_5.png"
import img13three from "../assets/images/collections/13/2_6.png"

import img14one from "../assets/images/collections/14/Colorway03 (1).png"
import img14two from "../assets/images/collections/14/Colorway03 (2).png"
import img14three from "../assets/images/collections/14/Colorway03 (3).png"

import img15one from "../assets/images/collections/15/lapel collar_Colorway A Copy 2_4.png"
import img15two from "../assets/images/collections/15/lapel collar_Colorway A Copy 2_5.png"
import img15three from "../assets/images/collections/15/lapel collar_Colorway A Copy 2_6.png"

import img16one from "../assets/images/collections/16/1.webp"
import img16two from "../assets/images/collections/16/2.webp"
import img16three from "../assets/images/collections/16/3.webp"

import img17one from "../assets/images/collections/17/1.webp"
import img17two from "../assets/images/collections/17/2.webp"
import img17three from "../assets/images/collections/17/3.webp"

import img18one from "../assets/images/collections/18/1.webp"
import img18two from "../assets/images/collections/18/2.webp"
import img18three from "../assets/images/collections/18/3.webp"

import img19one from "../assets/images/collections/19/1.webp"
import img19two from "../assets/images/collections/19/2.webp"
import img19three from "../assets/images/collections/19/3.webp"

import img20one from "../assets/images/collections/20/1.webp"
import img20two from "../assets/images/collections/20/2.webp"
import img20three from "../assets/images/collections/20/3.webp"

import { Helmet } from "react-helmet";




const CollectionWithImages = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Optional: Adds a smooth scrolling effect
              });
            
        }, 500);
    }, []);
    const items = [
        {
            id: 1,
            name: "MEN'S FLEECE HOODIE",
            code: "DZM-001",
            img: [img1one,img1two,img1three],
            link: "/product-details-1", 
            composition:"95% COTTON, 5% VISCOS. FLEECE GREY MELANGE, 320 GSM"
        },
        {
            id: 2,
            name: "LADIE'S OVERSIZED T-SHIRT",
            code: "DZW-002",
            // img: img2,
            img: [img2one,img2two,img2three],

            link: "/product-details-2",
             composition:"100% COTTON, 160 GSM PIGMENT PRINT"


        },
        {
            id: 3,
            name: "MEN'S SHACKET WITH FLAP POCKET",
            code: "DZM-003",
            // img: img3,
            img: [img3one,img3two,img3three],

            link: "/product-details-3",
            composition:"Poly, Rayon, Wool, Spandex Fabric."


        },
        {
            id: 4,
            name: "MEN'S FLEECE JOGGERS",
            code: "DZM-004",
            // img: img4,
            img: [img4one,img4two,img4three],

            link: "/product-details-4",
            composition:"FLEECE GREY MELANGE, 320 GSM"


        },
        {
            id: 5,
            name: "MEN'S LAPEL COLOR SHORT SLEEVED SHIRT",
            code: "DZM-005",
            // img: img5,
            img: [img5one,img5two,img5three],
            composition:"100% COTTON",

            link: "/product-details-5"

        },
        {
            id: 6,
            name: "MEN'S UTILITY JACKET",
            code: "DZM-006",
            // img: img6,
            img: [img6one,img6two,img6three],

            link: "/product-details-6",
            composition:"48% Polyester,41% Viscose,5% Polyamide,4% Acrylic,2% Wool"


        },
        {
            id: 7,
            name: "MEN'S UTILITY JACKET",
            code: "DZM-007",
            // img: img7,
            img: [img7one,img7two,img7three],

            link: "/product-details-7",
            composition:"48% Polyester,41% Viscose,5% Polyamide,4% Acrylic,2% Wool"


        },
        {
            id: 8,
            name: "LADIE'S OVERSIZED SWEATSHIRT",
            code: "DZW-008",
            // img: img8,
            img: [img8one,img8two,img8three],

            link: "/product-details-8",
            composition:"CVC, 260 GSM, TERRY ACID WASH, SEMI RUBBER PRINT"


        },
        {
            id: 9,
            name: "LADIE'S SWEATPANT",
            code: "DZW-009",
            // img: img9,
            img: [img9one,img9two,img9three],

            link: "/product-details-9",
            composition:"CVC, 260 GSM, TERRY ACID WASH."


        },
        {
            id: 10,
            name: "MEN'S PUFFER GILET",
            code: "DZM-0010",
            // img: img10,
            img: [img10one,img10two,img10three],

            link: "/product-details-10",
            composition:"Outer: 50% Polyester, 50% Recycled Polyester; Padding: 100% Polyester; Lining: 100% Polyester"


        },
        {
            id: 11,
            name: "MEN'S PUFFER GILET",
            code: "DZM-0011",
            // img: img11,
            img: [img11one,img11two,img11three],

            link: "/product-details-11",
            composition:"Outer: 50% Polyester, 50% Recycled Polyester & Sweat leather; Padding: 100% Polyester; Lining: 100% Polyester"


        },
        {
            id: 12,
            name: "LADIE'S LONG SWEATSHIRT",
            code: "DZW-008",
            // img: img12,
            img: [img12one,img12two,img12three],

            link: "/product-details-12",
            composition:"CVC, 260 GSM, TERRY ACID WASH, SEMI RUBBER PRINT"


        },
        {
            id: 13,
            name: "MEN'S LONG SLEEVE SHIRT AOP",
            code: "DZM-013",
            // img: img13,
            img: [img13one,img13two,img13three],

            link: "/product-details-13",
            composition:"65% Cotton 35% Viscose Fabric."


        },
        {
            id: 14,
            name: "MEN'S WINDBREAKER",
            code: "DZM-014",
            // img: img14,
            img: [img14one,img14two,img14three],

            link: "/product-details-14",
            composition:"Outer: 100% Polyester; Lining: 100% Polyester."


        },
        {
            id: 15,
            name: "MEN'S LAPEL COLOR SHORT SLEEVED SHIRT",
            code: "DZM-0015",
            // img: img15,
            img: [img15one,img15two,img15three],

            composition:"100% VISCOS",
            link: "/product-details-15",


        }, {
            id: 16,
            name: "MEN'S SHORTS",
            code: "DZM-016",
            // img: img16,
            img: [img16one,img16two,img16three],

            link: "/product-details-16",
            composition:"Scuba, 78% Poly, 15% viscose, 7% elastane"


        }, {
            id: 17,
            name: "LADIE'S RIB TANKTOP",
            code: "DZW-017",
            // img: img17,
            img: [img17one,img17two,img17three],

            link: "/product-details-17",
            composition:"95% COTTON 5% ELS, 140 GSM RIB AOP PRINT"


        }, {
            id: 18,
            name: "LADIE'S T-SHIRT ",
            code: "DZW-002",
            // img: img18,
            img: [img18one,img18two,img18three],

            link: "/product-details-18",
            composition:"100% COTTON, 140 GSM HIGH DENSITY PRINT/ PIGMENT PRINT"


        }, {
            id: 19,
            name: "MEN'S HOODIES",
            code: "DZM-019",
            // img: img19,
            img: [img19one,img19two,img19three],

            link: "/product-details-19",
            composition:"Scuba, 78% Poly, 15% viscose, 7% elastane"


        }, {
            id: 20,
            name: "LADIE'S POLO",
            code: "DZW-020",
            // img: img20,
            img: [img20one,img20two,img20three],

            link: "/product-details-20",
            composition:"CVC, 220 GSM, BABY TERRY SEMI RUBBER PRINT"


        }
    ];

    return (
        <div className="collection collection_comp">
         <Helmet>
        <title>Dezinin | Collections</title>
        <meta name="description" content="Dezinin-Collections" />
        <link rel="canonical" href="https://dezinin.com/collections" />
         </Helmet>
            <h1>COLLECTION</h1>
            <div className="grid-container">
                {items.map((item) => (
                    <Link to={item.link} key={item.id} className="card">
                        <LazyLoad height={200} offset={100}>
                            <img src={item.img[0]} alt={item.name} className="card-image"  />
                        </LazyLoad>
                        <div className="card-details">
                            <h3>{item.name}</h3>
                            <p>{item.code}</p>
                        </div>
                        <button className="favorite-btn">♥</button>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CollectionWithImages;
