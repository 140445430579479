import React from "react";
import {
	Box,
	Container,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyles";
import { useHistory } from 'react-router-dom';
const Footer = () => {
	const history = useHistory();
	const phoneNumber = "+8801860963169";
	const handleWhatsAppCall = () => {
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank"); // Opens WhatsApp chat in a new tab
	};
	const handleRedirect = (path) => {
		history.push(`/${path}`)
	}
	return (
		<Box className="footer_main">

			<Container>
				<Row>
					<Column>
						<Heading className="footer_heading">Let's talk</Heading>
						<FooterLink onClick={handleWhatsAppCall} className="cursor_pointer">Give use a call</FooterLink>
						<FooterLink onClick={() => handleRedirect("our-promise")} className="cursor_pointer">Vision</FooterLink>
						<FooterLink onClick={() => handleRedirect("contact")} className="cursor_pointer" >Contact us</FooterLink>
					</Column>
					{/* <Column>
			<Heading>Services</Heading>
			<FooterLink href="#">Writing</FooterLink>
			<FooterLink href="#">Internships</FooterLink>
			<FooterLink href="#">Coding</FooterLink>
			<FooterLink href="#">Teaching</FooterLink>
		</Column> */}
					<Column>
						<Heading className="footer_heading">Contact Us</Heading>
						<FooterLink >House-3, (4th Floor)</FooterLink>
						<FooterLink >Road-16, Sector-11 Uttara</FooterLink>
						<FooterLink >Dhaka-1230</FooterLink>
					</Column>
					<Column>
						<Heading className="footer_heading">Social Media</Heading>
						<FooterLink href="https://www.facebook.com/dezininfashion" target="_blank">
							<i className="fab fa-facebook-f">
								<span style={{ marginLeft: "10px", fontFamily: "Inter" }}>
									Facebook
								</span>
							</i>
						</FooterLink>
						<FooterLink href="https://www.instagram.com/dezininfashion/
" target="_blank">
							<i className="fab fa-instagram">
								<span style={{ marginLeft: "10px", fontFamily: "Inter" }}>
									Instagram
								</span>
							</i>
						</FooterLink>

						<FooterLink href="https://bd.linkedin.com/company/dezinin
" target="_blank">
							<i className="fab fa-linkedin">
								<span style={{ marginLeft: "10px", fontFamily: "Inter" }}>
									Linkedin
								</span>
							</i>
						</FooterLink>
					</Column>
				</Row>
			</Container>
			<Container className="footer_copy_right">
				
				<p>&copy;  All rights reserved by Dezinin.</p>
				
				</Container>
		</Box>
	);
};
export default Footer;
