import React, { Component } from "react";
import Fade from "react-reveal";
import artImage from "../../assets/images/story.png";
import WomenEmpower from "../../assets/images/Womenempower.jpg";
import Sustainable from "../../assets/images/sustainable.webp";
import coverOne from "../../assets/images/coverOne.png"
import coverTwo from "../../assets/images/coverTwo.jpg"
import Carousel from "react-multi-carousel";
import coverOne_graf from "../../assets/images/cover1 CRAFTING A GREENER TOMORROW.jpg"
import coverOne_graf_two from "../../assets/images/cover2 CRAFTING A GREENER TOMORROW.jpg"
import coverOne_exceptional from "../../assets/images/cover1 EXCEPTIONAL SPEED AND EXCELLENCE.jpg"
import coverOne_exceptional_two from "../../assets/images/cover2 EXCEPTIONAL SPEED AND EXCELLENCE.jpg"
import Innovative from "../../assets/images/INNOVATIVE_TRANSFORM_SPRODUCTION_LANDSCAPE.jpg"

import "react-multi-carousel/lib/styles.css";


export class DezininCarosol extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isEdit: false,
          department_name: "",
          foo: "bar",
          resumeData: {}
    
        };
        
    
      }
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    const captionStyle = {
      fontSize: "2em",
      fontWeight: "bold",
    };
    const slideNumberStyle = {
      fontSize: "20px",
      fontWeight: "bold",
    };
    console.log("immmmmmm",this.props.carouselData)
    return (
      <section id="collection" className="collection_carosol">
        {/* <Fade  distance="40px"> */}
        <div className="row">
          <div style={{ textAlign: "center" }}>
            {/* <h2>React Carousel Minimal</h2>
          <p>Easy to use, responsive and customizable carousel component for React Projects.</p> */}
            <div
            // style={{
            //   padding: "0 20px",
            // }}
            >
              <Carousel swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px">
                    {
                        this.props?.carouselData?.map((data)=>(
                            <div className="carosel_part">
                  <img src={data?.img} />
                  <p className="legend">
                    {
                        data?.text
                    }
                  </p>
                </div>

                        ))
                    }
                
                {/* <div className="carosel_part">
                  <img src={coverOne_graf_two} />
                  <p className="legend">Crafting a Greener
                      <br/>
                      Tomorrow</p>
                </div>
                <div className="carosel_part">
                  <img src={Innovative} />
                  <p className="legend"> Innovative Design
                      <br/>
                      Transforms Production Landscape!</p>
                </div>
                <div className="carosel_part">
                  <img src={coverOne_exceptional} />
                  <p className="legend"> Exceptional speed
                      <br/>
                      and excellence</p>
                </div>
                <div className="carosel_part">
                  <img src={coverOne_exceptional_two} />
                  <p className="legend"> Exceptional speed
                      <br/>
                      and excellence</p>
                </div> */}
              </Carousel>

              {/* <Carousel infiniteLoop={true}>
                <div>
                    <img src={coverOne} />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src={coverTwo} />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src={WomenEmpower}/>
                    <p className="legend">Legend 3</p>
                </div>
            </Carousel> */}
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </section>
    );
  }
}

export default DezininCarosol;
