import React, { Component } from "react";
import Collections from "./homePage/Collections";
import ReactGA from "react-ga";
import $ from "jquery";
import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder,
} from "@cloudinary/react";
import imageOne from "../assets/images/1.jpg";
import JoinUsMembership from "./homePage/JoinUsMembership";
import DezininCarosol from './Carousel/Carousel';
import coverOne_graf from "../assets/images/cover1 CRAFTING A GREENER TOMORROW.jpg"
import serviceOne from "../assets/images/service/ourpromise/OUR PROMISE-01.png"
import serviceTwo from "../assets/images/service/ourpromise/OUR PROMISE-02.png"
import serviceThree from "../assets/images/service/ourpromise/OUR PROMISE-03.png"
import serviceFour from "../assets/images/service/ourpromise/OUR PROMISE-04.png"
import TrendFullImage from "../assets/images/DESIGN PACKAGE1-01_trend.jpg"
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
import ContactFormJoinUs from "./ContactFormJoinUs"

export class SupplyChain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      department_name: "",
      foo: "bar",
      resumeData: {},
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }
  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    const carouselData = [
      {
        text: "Beautiful sunset over the mountains",
        img: serviceOne
      },
      {
        text: "Calm beach with crystal clear water",
        img: serviceTwo
      },
      {
        text: "Lush green forest trail",
        img: serviceThree
      },
      {
        text: "Skyline of a bustling city at night",
        img: serviceFour
      }
    ];
    
    return (
      <div className="our_promise">
        <Helmet>
        <title>Dezinin | Our promise</title>
        <meta name="description" content="Dezinin-Our promise" />
        <link rel="canonical" href="https://dezinin.com/our-promise" />
         </Helmet>
         <LazyLoad height={200} offset={100}>
      <img
        src={serviceOne}
        alt="Lazy Loaded Example"
        // style={{ width: "300px", height: "200px" }}
      />
    </LazyLoad> 
    <LazyLoad height={200} offset={100}>
      <img
        src={serviceTwo}
        alt="Lazy Loaded Example"
        // style={{ width: "300px", height: "200px" }}
      />
    </LazyLoad> 
    <LazyLoad height={200} offset={100}>
      <img
        src={serviceThree}
        alt="Lazy Loaded Example"
        // style={{ width: "300px", height: "200px" }}
      />
    </LazyLoad>
     <LazyLoad height={200} offset={100}>
      <img
        src={serviceFour}
        alt="Lazy Loaded Example"
        // style={{ width: "300px", height: "200px" }}
      />
    </LazyLoad>
        
        {/* <div className="image_and_text">
          <img
            src={imageOne}
            alt="Nature"
            class="responsive_img"
            width="600"
            height="400"
          />
          <div class="centered_text">
            <h1 className="image_text_header">3D & Development</h1>
            <br />
            <p>Trend Flash</p>
            <h4>Chintzy Floras</h4>
            <button>Explore now</button>
          </div>
        </div> */}
        {/* <div className="row trend_image_full">
          <div>
            <h6 className="header_text_size">DEZININ OFFERS FASHION DESIGN SERVICES THAT AIMS TO ASSIST AND GUIDE START UP FASHION BRANDS, FACTORIES AND BUSINESS.</h6>
          </div>
          <img src={TrendFullImage} />
        </div> */}
        <div>
        <ContactFormJoinUs/>

        </div>
      </div>
    );
  }
}

export default SupplyChain;