// SkeletonLoader.js
import React from 'react';
import './SkeletonLoader.css'; // We'll add the CSS styles in the next step

const SkeletonLoader = (props) => {
    let { width = '100%', height = '20px', borderRadius = '4px' ,skeletonNumber=5}=props
    const skeletons = Array.from({ length: skeletonNumber }).map((_, i) => (
        <div
          key={i}
          className="skeleton-loader"
          style={{ width, height, borderRadius }}
        ></div>
      ));
  return (
    skeletons
  );
};

export default SkeletonLoader;
