import React, { Component } from 'react';
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Navigation from "../navigation";
 import Footer from "../footer"
import Collections from './Collections';
import Sustainability from './SustainabilityPart';
import ReactGA from "react-ga";
import $ from "jquery";
import Trend from './Trend';
import DezininCarosol from './DezininCarosol';
import Services from "./Services"
import OurPromise from './OurPromise';
import JoinUsMembership from './JoinUsMembership';
import News from './News';
import ContactFormJoinUs from "../ContactFormJoinUs"
import { Helmet } from "react-helmet";
class home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      department_name: "",
      foo: "bar",
      resumeData: {}

    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);

  }
  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {

      setTimeout(() => {
          window.scrollTo({
              top: 0,
              behavior: 'smooth', // Optional: Adds a smooth scrolling effect
            });
          
      }, 500);

    this.getResumeData();
  }

  render() {
console.log("props",this.props)
    return <div>
       <Helmet>
        <title>Dezinin</title>
        <meta name="description" content="Dezinin" />
        <link rel="canonical" href="https://www.dezinin.com/" />
      </Helmet>
        <DezininCarosol/>
        <News/>
       
        <Trend/>
        {/* <DezininCarosolNext/> */}
        <Services/>
        <Sustainability/>
       
        {/* <Collections data={this.state.resumeData.collection} /> */}
        <OurPromise/>
        {/* <JoinUsMembership/> */}
        <ContactFormJoinUs/>
       
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(withRouter(home));
