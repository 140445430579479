import React, { useState } from 'react';
import axios from 'axios';
import Loader from "./Loader/Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ProductEnquiryForm.css";

const ProductEnquiryForm = (props) => {

    const [name, setName] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const handleChangeName = (e) => {
        setName(e.target.value)
    }
    const handleChangeCompanyname = (e) => {
        setCompanyname(e.target.value)
    }
    const handleChangeCity = (e) => {
        setCity(e.target.value)
    }
    const handleChangeCountry = (e) => {
        setCountry(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
    }
    const clearState = () => {
        setName("")
        setCompanyname("")
        setEmail("")
        setPhone("")
        setCity("")
        setCountry("")
        setMessage("")

    }
    function isValidEmail(email) {
        // Define a regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleSubmit = (e) => {
        //  let isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (message && isValidEmail(email)) {
            setLoader(true)
            let submitObj = {
                name: name,
                companyname: companyname,
                email: email,
                city: city,
                country: country,
                phone: phone,
                message: `Enquiry for product id: ${props?.productId}.${message}`,
            }
            axios({
                method: 'POST',
                // url: 'http://localhost:5000/sendmail_contact',
                // url: 'https://send-email-one.vercel.app/sendemail',
                url: 'https://send-email-one.vercel.app/sendmail_contact',

                data: submitObj
            }).then((res) => {
                toast("Successfully send your mail")
                setLoader(false)
                clearState()

            }).catch((err) => {
                console.log("response err", err)
                setLoader(false)
                toast("Something wrong")
            });
        } else {
            setLoader(false)
        }

    }

  return (
    <div className="form-container product_inquiry_main">
      <h2>Product Enquiry Request Form</h2>
      <p className="enquiry_details_text">
        Please fill out the below details to send your product selection and
        comments to us. We will be in contact shortly to follow up. Thank you.
      </p>
      <div className="enquiry-form">
        <div className="form-group">
          <label htmlFor="name">
            Name: <span className="required">*</span>
          </label>
          <input onChange={(e) => handleChangeName(e)} value={name} type="text" id="name" name="name"  placeholder="Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="company">
            Company Name:
          </label>
          <input onChange={(e) => handleChangeCompanyname(e)} value={companyname} type="text" id="companyname" name="companyname" placeholder="Company Name" />
        </div>
        <div className="form-group">
          <label htmlFor="email">
            Email Address: <span className="required">*</span>
          </label>
          <input onChange={(e) => handleChangeEmail(e)} value={email} type="email" id="email" name="email" placeholder="Email Address" required />
        </div>
        <div className="form-group">
          <label htmlFor="phone">
            Phone Number:
          </label>
          <input  onChange={(e) => handleChangePhone(e)} value={phone} type="text" id="phone" name="phone" placeholder="Phone Number" />
        </div>
        <div className="form-group">
          <label htmlFor="message">
            Message: <span className="required">*</span>
          </label>
          <textarea onChange={(e) => handleChangeMessage(e)} value={message} id="message" name="message" placeholder="Type your message here..." required></textarea>
        </div>
        <div className='submit_btn_diiv'>
        <button className="submit-btn enquiry_submit_btn"onClick={(e) => handleSubmit(e)} >Submit Selection</button>
        {loader ? <Loader /> : ""}
        </div>
        {/* <p className="mandatory-note">* Mandatory Field</p> */}
        </div>
    
    </div>
  );
};

export default ProductEnquiryForm;
